import { useEffect, useState } from "react"; // Importa o useState para gerenciamento de estado
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  Input,
  Textarea,
  Select,
  Image,
  FormLabel,
  FormControl,
  Button as ChakraButton,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import { APICore } from "../../../helpers/api/apiCore";

const api = new APICore();

const EditRaffleModal = ({
  isModalOpen,
  closeModal,
  selectedRaffle,
  objectDisabled,
  setObjectDisabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const toast = useToast();

  const handleInputChange = (field, value) => {
    setObjectDisabled((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ["image/jpeg", "image/png", "image/bmp", "image/webp"];
      if (validTypes.includes(fileType)) {
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file)); // Gera uma URL temporária para o preview
      } else {
        alert(
          "Por favor, selecione um arquivo de imagem válido (jpg, jpeg, png, bmp, webp)."
        );
      }
    }
  };

  // Função para salvar os dados da rifa
  const handleSave = async () => {
    setLoading(true);

    const raffleData = {
      title: objectDisabled?.title,
      description: objectDisabled?.description,
      image: selectedFile,
      type: objectDisabled?.type,
      price: objectDisabled?.price,
    };

    if (selectedRaffle) {
      // Chamada à API para criar a nova rifa
      api
        .createWithFile(
          "/bancas/raffles/" + selectedRaffle?.raffleId + "/update",
          raffleData
        )
        .then((response) => {
          if (response.data) {
            // Fechar o modal
            closeModal();
            // Recarregar a página
            window.location.reload();
          } else {
            // Caso a API não retorne dados esperados
            toast({
              title: "Erro ao criar rifa",
              description: "error",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          // Tratamento de erros na chamada à API
          toast({
            title: "Erro ao criar rifa.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false); // Finaliza o carregamento
        });
    } else {
      // Chamada à API para criar a nova rifa
      api
        .createWithFile("/bancas/raffles", raffleData)
        .then((response) => {
          if (response.data) {
            // Fechar o modal
            closeModal();
            // Recarregar a página
            window.location.reload();
          } else {
            // Caso a API não retorne dados esperados
            toast({
              title: "Erro ao criar rifa",
              description: "error",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          // Tratamento de erros na chamada à API
          toast({
            title: "Erro ao criar rifa.",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false); // Finaliza o carregamento
        });
    }
  };

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview); // Libera a memória usada pela URL temporária
      }
    };
  }, [preview]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedRaffle ? "Editar Detalhes da Rifa" : "Criar Nova Rifa"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {/* Campos do formulário */}
            <FormControl>
              <FormLabel>Título</FormLabel>
              <Input
                placeholder="Preencha o título da rifa"
                value={objectDisabled?.title || ""}
                onChange={(e) => handleInputChange("title", e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <Textarea
                placeholder="Preencha a descrição da rifa. Inclua detalhes do sorteio e os itens premiados."
                value={objectDisabled?.description || ""}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </FormControl>
            {selectedRaffle ? null : (
              <>
                <FormControl>
                  <FormLabel>Tipo</FormLabel>
                  <Select
                    placeholder="Selecione..."
                    value={objectDisabled?.type || ""}
                    onChange={(e) => handleInputChange("type", e.target.value)}
                  >
                    {/* <option value="MIL">Mil números</option>
                    <option value="DEZ_MIL">10 mil números</option> */}
                    <option value="CEM_MIL">100 mil números</option>
                    {/* <option value="UM_MILHAO">1 milhão de números</option>
                    <option value="DEZ_MILHOES">10 milhões de números</option> */}
                  </Select>
                </FormControl>
                {selectedRaffle ? (
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      placeholder="Selecione..."
                      value={objectDisabled?.status || ""}
                      onChange={(e) =>
                        handleInputChange("status", e.target.value)
                      }
                    >
                      <option value="OPEN">Em andamento</option>
                      <option value="CLOSED">Finalizado</option>
                    </Select>
                  </FormControl>
                ) : null}
                <FormControl>
                  <FormLabel>Valor da Cota</FormLabel>
                  <Input
                    type="text"
                    placeholder="Valor da Cota"
                    value={
                      objectDisabled?.price !== undefined &&
                      objectDisabled?.price !== null
                        ? new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(objectDisabled.price)
                        : ""
                    }
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/[^\d]/g, ""); // Apenas números
                      const numericValue = parseFloat(rawValue) / 100; // Ajusta para valores decimais

                      handleInputChange(
                        "price",
                        isNaN(numericValue) ? 0 : numericValue
                      );
                    }}
                    onBlur={(e) => {
                      const rawValue = e.target.value.replace(/[^\d]/g, ""); // Apenas números
                      const numericValue = parseFloat(rawValue) / 100; // Ajusta para valores decimais

                      handleInputChange(
                        "price",
                        isNaN(numericValue) ? 0 : numericValue
                      );
                    }}
                  />
                </FormControl>
              </>
            )}
            <FormControl>
              <FormLabel>Imagem</FormLabel>
              <Input
                type="file"
                accept=".jpg,.jpeg,.png,.bmp,.webp"
                onChange={(e) => handleFileChange(e)}
              />
            </FormControl>

            <Image
              src={preview || objectDisabled?.image}
              alt={objectDisabled?.title || "Preview da imagem"}
              borderRadius="md"
              mb={4}
              fallbackSrc="/no-image.png"
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ChakraButton
            colorScheme="yellow"
            leftIcon={<Icon as={FaSave} />}
            onClick={handleSave}
            isLoading={loading}
            loadingText="Salvando..."
          >
            Salvar
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditRaffleModal;
