import { Row, Col, Card } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";

// COMPONENTS
import PageTitle from "../../../components/PageTitle";

// HELPERS
import { APICore } from "../../../helpers/api/apiCore";

// HOOKS
import useEvent from "../../../hooks/useEvent";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Spacer,
  Table,
  Tag,
  Td,
  Text,
  Th,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { toggleLoader } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import LoaderTable from "../../../components/LoaderTable";

/**
 * HELPERS
 */
const api = new APICore();
const configs = {
  title: "Painel de suporte",
  path: "/support/",
};

/**
 * COMPONENT
 */
const Support = () => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<any[]>([]);
  const [id, setId] = useState<string>("");
  const [idBase, setIdBase] = useState<string>("");
  const [idCollector, setIdCollector] = useState<string>("");
  const [paymentData, setPaymentData] = useState<any>(null);
  const [blacklistData, setBlacklistData] = useState<any>(null);
  const [bases, setBases] = useState<any[]>([]);

  const toast = useToast();

  /**
   * CUSTOM HOOKS
   */
  const { subscribe, unsubscribe } = useEvent();

  /**
   * LIFE CYCLE HELPERS
   */
  const handlePageLoading = useCallback(async () => {
    await api.get("/system/support", null)?.then((response) => {
      setData(response?.data ?? []);
    });
  }, []);

  const handlePageInit = useCallback(() => {
    subscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageDestroy = useCallback(() => {
    unsubscribe("deleteItem", handlePageLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LIFE CYCLE
   */
  useEffect(() => {
    const getBlacklist = async () => {
      dispatch(toggleLoader(true));
      await api
        .get(`/system/blacklist`, null)
        .then((response) => {
          if (response?.data) {
            setBlacklistData(response.data || {});
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao buscar dados de blacklist",
            description: error ?? "Erro ao buscar dados de blacklist",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      dispatch(toggleLoader(false));
    };

    const getBases = async () => {
      dispatch(toggleLoader(true));
      await api
        .get(`/base`, null)
        .then((response) => {
          if (response?.data) {
            setBases(response.data || {});
          }
        })
        .catch((error) => {
          toast({
            title: "Erro ao buscar dados das bases",
            description: error ?? "Erro ao buscar dados das bases",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      dispatch(toggleLoader(false));
    };

    getBlacklist();
    getBases();

    handlePageInit();
    handlePageLoading();

    const intervalId = setInterval(() => {
      handlePageLoading();
    }, 3000);

    return () => {
      clearInterval(intervalId);
      handlePageDestroy();
    };
  }, [handlePageInit, handlePageLoading, handlePageDestroy]);

  const handleRunJob = async (routine: any) => {
    if (window.confirm("Deseja realmente executar essa rotina?") === false) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/support/${routine}`, null)
      .then((response) => {
        if (response?.data) {
          toast({
            title: "Sucesso",
            description: "Rotina iniciada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao executar rotina",
          description: error ?? "Erro ao executar rotina",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleGetPayment = async () => {
    dispatch(toggleLoader(true));
    await api
      .create(`/system/recharges`, { id })
      .then((response) => {
        if (response?.data) {
          setPaymentData(response.data || {});
        }
      })
      .catch((error) => {
        toast({
          title: "Erro ao buscar dados de pagamento",
          description: error ?? "Erro ao buscar dados de pagamento",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleGetExcelUnits = async () => {
    dispatch(toggleLoader(true));
    await api
      .getFile(`/system/helpers/unit/export`, {
        idBase,
        idCollector,
        format: "excel",
      })
      .then((response) => {
        // Obter o nome do arquivo do cabeçalho Content-Disposition
        const contentDisposition = response.headers["content-disposition"];

        let fileName = `UNIDADES - BASE: ${idBase} - ${
          idCollector ? idCollector : ""
        }.xlsx`; // Nome padrão caso o cabeçalho não exista
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length > 1) {
            fileName = fileNameMatch[1]; // Extrair o nome do arquivo
          }
        }

        // Criar um link temporário para baixar o arquivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Usar o nome extraído
        document.body.appendChild(link);
        link.click();

        // Remover o link após o download
        link?.parentNode.removeChild(link);
      })
      .catch((error) => {
        toast({
          title: "Erro ao exportar dados",
          description: error ?? "Erro ao exportar dados",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleResetJob = async (type: string) => {
    if (!window.confirm("Deseja realmente librar o job *" + type + "* ?")) {
      return;
    }

    dispatch(toggleLoader(true));
    await api
      .create(`/system/helpers/jobs/unlock`, { type })
      .then((response) => {
        console.log("Liberado.");
      })
      .catch((error) => {
        toast({
          title: "Erro ao liberar lock",
          description: error ?? "Erro ao liberar lock",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    dispatch(toggleLoader(false));
  };

  const handleReprocessPayment = async () => {
    dispatch(toggleLoader(true));

    try {
      const response = await fetch(
        "https://webhook.sistemabarao.com.br/receive/PAGOEXPRESS",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uniqueId: id }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Pagamento reprocessado",
          description: data?.message || "Reprocessado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Erro ao reprocessar pagamento",
          description: data?.message || "Erro ao reprocessar pagamento",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Erro ao reprocessar pagamento",
        description: "Pagamento não realizado.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    dispatch(toggleLoader(false));
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {
            label: configs.title,
            path: configs.path,
            active: true,
          },
        ]}
        title={configs.title}
      />

      {layout.loading && <LoaderTable />}

      <Row>
        <Col sm={6}>
          <Card>
            <Card.Body>
              <Text fontWeight={600}>Exportar unidades:</Text>

              <Flex gap={1}>
                <Select
                  placeholder="Banca..."
                  onChange={(e) => setIdBase(e.target.value)}
                >
                  {bases?.map((item: any) => (
                    <option value={item.idBase}>{item.title}</option>
                  ))}
                </Select>
                <Spacer />
                <Input
                  type="text"
                  placeholder="Promotor"
                  onChange={(e) => setIdCollector(e.target.value)}
                />
                <Spacer />
                <Button onClick={handleGetExcelUnits}>OK</Button>
              </Flex>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Table>
                <Tr>
                  <Th>Rotina</Th>
                  <Th>Ação</Th>
                  <Th>JOB</Th>
                </Tr>

                {data?.map((routine, index) => (
                  <Tr key={index}>
                    <Td>{routine?.name}</Td>
                    <Td>
                      {!routine?.already ? (
                        <Link onClick={() => handleRunJob(routine?.type)}>
                          <span className="badge bg-success">Rodar</span>
                        </Link>
                      ) : (
                        <>
                          <span className="badge bg-secondary">
                            {routine?.createdAt}
                          </span>
                          <br />
                          <span className="badge bg-info">Executando</span>
                        </>
                      )}
                    </Td>
                    <Td>
                      <Box
                        cursor={"pointer"}
                        onClick={() => handleResetJob(routine?.type)}
                      >
                        <span className="badge bg-danger">Reiniciar</span>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card>
            <Card.Body>
              <Text fontWeight={600}>Consultar recarga:</Text>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="ID PagoExpress"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleGetPayment}>
                    Buscar
                  </Button>
                </InputRightElement>
              </InputGroup>
              <hr />
              {paymentData ? (
                <>
                  <Table>
                    <Tr>
                      <Th>BASE</Th>
                      <Td>{paymentData?.base}</Td>
                    </Tr>
                    <Tr>
                      <Th>UNIDADE</Th>
                      <Td>{paymentData?.idUnit}</Td>
                    </Tr>
                    <Tr>
                      <Th>CONTA</Th>
                      <Td>{paymentData?.account}</Td>
                    </Tr>
                    <Tr>
                      <Th>VALOR</Th>
                      <Td>R$ {formatCurrency(paymentData?.amount || 0)}</Td>
                    </Tr>
                    <Tr>
                      <Th>STATUS</Th>
                      <Td>
                        <Tag
                          colorScheme={
                            paymentData?.status === "paid" ? "green" : "red"
                          }
                        >
                          {paymentData?.status === "paid"
                            ? "PAGO"
                            : paymentData?.status}
                        </Tag>
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>GERADO</Th>
                      <Td>{paymentData?.createdAt}</Td>
                    </Tr>
                    <Tr>
                      <Th>NOTIFICADO</Th>
                      <Td>{paymentData?.updatedAt}</Td>
                    </Tr>
                  </Table>
                  <Center>
                    <Button
                      mt={4}
                      size="xs"
                      colorScheme="red"
                      onClick={handleReprocessPayment}
                    >
                      REPROCESSAR
                    </Button>
                  </Center>
                </>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <Card>
            <Card.Body>
              <Table>
                <Tr>
                  <Th>CPF</Th>
                  <Th>MOTIVO</Th>
                </Tr>

                {blacklistData?.map((blacklist, index) => (
                  <Tr key={index}>
                    <Td>{blacklist?.identifier}</Td>
                    <Td>{blacklist?.observation}</Td>
                  </Tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Support;
